import { mutateGraphQL } from '@/data/apollo';
import { EmployeesPublicTableWriteMutation, EmployeesPublicTableWriteMutationVariables } from '@/generated/graphql';
import { EmployeesWrite } from '@/graphql/employee.graphql';
import useAccountType from '@/helpers/useAccountType';
import { EmployeesPublicTableWrite } from '@/pages/client/employees/employeeGQL';
import saveAndPostItems from '@/pages/dashboard/management/inventory/itemUtils';
import { useEvents } from '@/providers/event';
import { Employee, Item, MutationEmployeesWriteArgs } from '@/types/schema';
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { isEmpty, pick, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';
import AsyncLoadingButton from '../../form/asyncLoading/asyncLoadingButton';
import { GraphqlTableSavableData } from './index';

export default function QuickEditSaveButton( { tableSavableData }: { tableSavableData: GraphqlTableSavableData } ) {
	const { enqueueSnackbar } = useSnackbar();
	const event = useEvents();
	const isCloverAccount = useAccountType( 'CLOVER' ) && tableSavableData.table === 'items';
	
	return (
		<Tooltip title='Save Edited Fields'>
			<Box>
				<AsyncLoadingButton
					variant='outlined'
					startIcon={<SaveIcon/>}
					disabled={isEmpty( tableSavableData?.data )}
					onClick={async () => {
						if ( tableSavableData.table === 'items' ) {
							try {
								await saveAndPostItems( tableSavableData.data as Item[] );
								event.emit( 'reload.graphqlQuery', true );
								event.emit( 'quick.edit', { table: 'items', data: [] } );
								event.emit( 'quick.save', { table: 'items', data: [] } );
								event.removeListener( 'quick.edit', () => null );
							} catch {
							}
							
						} else if ( tableSavableData.table === 'employees' ) {
							try {
								
								for ( const employee of tableSavableData.data as Employee[] ) {
									
									const dailyLimit = employee.dailyLimit;
									const monthlyLimit = employee.monthlyLimit;
									const totalLimit = employee.totalLimit;
									const hasDailyLimit = dailyLimit > 0;
									const hasMonthlyLimit = monthlyLimit > 0;
									const hasTotalLimit = totalLimit > 0;
									
									if ( hasDailyLimit ) {
										if ( hasMonthlyLimit && dailyLimit > monthlyLimit ) {
											enqueueSnackbar( 'Daily limit cannot be more than monthly limit', { variant: 'error' } );
											return;
										}
										if ( hasTotalLimit && dailyLimit > totalLimit ) {
											enqueueSnackbar( 'Daily limit cannot be more than total limit', { variant: 'error' } );
											return;
										}
									}
									
									if ( hasMonthlyLimit ) {
										if ( hasTotalLimit && monthlyLimit > totalLimit ) {
											enqueueSnackbar( 'Monthly limit cannot be more than total limit', { variant: 'error' } );
											return;
										}
									}
								}
								
								await mutateGraphQL<EmployeesPublicTableWriteMutationVariables, EmployeesPublicTableWriteMutation>( {
									mutation : EmployeesPublicTableWrite,
									variables: {
										inputs: tableSavableData.data?.map( ( employee: Employee ) => ( {
											id  : employee.id,
											name: employee.name,
											...pick( employee, [
												'name',
												'phone',
												'active',
												'personalId',
												'absorbValue',
											] ) as any,
											email       : employee.email ? toLower( employee.email ) : null,
											dailyLimit  : employee.dailyLimit || null,
											monthlyLimit: employee.monthlyLimit || null,
											totalLimit  : employee.totalLimit || null,
											cardNumber  : employee.cardNumber,
										} ) ),
									},
								} );
								
								event.emit( 'reload.graphqlQuery', true );
								event.emit( 'quick.edit', { table: 'employees', data: [] } );
								event.emit( 'quick.save', { table: 'employees', data: [] } );
								event.removeListener( 'quick.edit', () => null );
							} catch {
							}
						}
					}}>
					{isCloverAccount ? ' Save & Sync' : 'Save'}
				</AsyncLoadingButton>
			</Box>
		</Tooltip>
	);
}

export function QuickEditCancelButton() {
	const event = useEvents();
	const { t } = useTranslation();
	
	return (
		<Tooltip title='Cancel'>
			<Box>
				<AsyncLoadingButton
					variant='outlined'
					startIcon={<CloseIcon/>}
					onClick={() => {
						event.emit( 'quick.edit', { table: 'items', data: [] } );
						event.emit( 'quick.save', { table: 'items', data: [] } );
						event.emit( 'quick.edit', { table: 'employees', data: [] } );
						event.emit( 'quick.save', { table: 'employees', data: [] } );
						event.removeListener( 'quick.edit', () => null );
						event.removeListener( 'quick.save', () => null );
						event.emit( 'reload.graphqlQuery', true );
					}}>
					{t( 'common:cancel' )}
				</AsyncLoadingButton>
			</Box>
		</Tooltip>
	);
}
